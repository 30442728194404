// src/Header.js

import React, { useState } from "react";
import { Link } from "react-router-dom"; // If using React Router for navigation

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-gray-800 text-white">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="text-xl font-bold">
            דניאל אדרי עיצוב שיער
          </Link>
          <img
            src={require("../../src/img/logo.svg").default}
            alt="לוגו"
            className="h-10 w-10 mr-2"
          />
        </div>
        <div
          
          className="hidden md:flex items-center space-x-8 space-x-reverse"
        >
          <Link to="/" className="hover:text-gray-300 mr-4">
            בית
          </Link>
          <Link to="/services" className="hover:text-gray-300 mr-4">
            שירותים
          </Link>
          <Link to="/gallery" className="hover:text-gray-300 mr-4">
            גלריה
          </Link>
          <Link to="/about" className="hover:text-gray-300 mr-4">
            אודות
          </Link>
          <Link to="/contact" className="hover:text-gray-300 mr-4">
            צורקשר
          </Link>
          <a
            href="https://www.instagram.com/daniel.edri.barber/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-300 mr-4"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
      </nav>
      <div className={`${isMobileMenuOpen ? "block" : "hidden"} md:hidden`}>
        <Link to="/" className="block py-2 px-4 text-sm hover:bg-gray-700">
          בית
        </Link>
        <Link
          to="/services"
          className="block py-2 px-4 text-sm hover:bg-gray-700"
        >
          שירותים
        </Link>
        <Link
          to="/gallery"
          className="block py-2 px-4 text-sm hover:bg-gray-700"
        >
          גלריה
        </Link>
        <Link to="/about" className="block py-2 px-4 text-sm hover:bg-gray-700">
          אודות
        </Link>
        <Link
          to="/contact"
          className="block py-2 px-4 text-sm hover:bg-gray-700"
        >
          צור קשר
        </Link>
        <a
          href="https://www.instagram.com/daniel.edri.barber/"
          target="_blank"
          rel="noopener noreferrer"
          className="block py-2 px-4 text-sm hover:bg-gray-700"
        >
          <i className="fab fa-instagram"></i> אינסטגרם
        </a>
      </div>
    </header>
  );
};

export default Header;
